import { Box, Flex } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import FeaturedItem from "areas/landing/elements/slider/featured-item"
import { getUrlProps } from "helpers/landing"
import { hideScrollbar } from "styles/utils"

import ColumnProduct from "./column-product"
import Image from "./image"
import Textarea from "./textarea"

const StyledFlex = styled(Flex).attrs((props) => ({
  flexWrap: "wrap",
  ...props,
}))`
  ${hideScrollbar}
`

const Column = styled(Box).attrs((props) => ({
  flex: { _: "1 1 100%", tablet: "1" },
  position: "relative",
  width: "100%",
  ...props,
}))``

const TextBlock = styled(Box).attrs((props) => ({
  ...props,
}))``

const Row = ({ element, lazyload }) => (
  <Box contain={element.contain} {...element.style?.container_style}>
    <StyledFlex {...element.style}>
      {element.columns.map((column, column_index) => {
        const urlProps = getUrlProps(
          column.url,
          column.link_id || element.anchor_name || element.id
        )

        return (
          <Column
            key={`${element.id}-${column_index}`}
            {...column.column_style}
          >
            <Box
              aria-label={column.aria_label}
              display="block"
              height="100%"
              {...urlProps}
            >
              {column.components.map((component, component_index) => {
                if (component.type === "featured") {
                  return (
                    <FeaturedItem
                      item={component}
                      key={`${element.id}-${column_index}-${component_index}`}
                      lazyload={lazyload}
                      linkClickData={element.anchor_name || element.id}
                    />
                  )
                } else if (component.type === "product") {
                  return (
                    <ColumnProduct
                      element={element}
                      index={column_index}
                      key={`${element.id}-${column_index}-${component_index}`}
                      lazyload={lazyload}
                      product={component}
                    />
                  )
                } else if (component.image) {
                  let finalUrlProps = urlProps
                  if (component.image.url) {
                    finalUrlProps = getUrlProps(
                      component.image.url,
                      element.anchor_name || element.id
                    )
                  }
                  return (
                    <Box
                      key={`${element.id}-${column_index}-${component_index}`}
                      {...finalUrlProps}
                      {...component.image.style}
                    >
                      <Box position="relative">
                        <Image image={component.image} lazyload={lazyload} />
                      </Box>
                    </Box>
                  )
                } else if (component.textarea) {
                  return (
                    <TextBlock
                      key={`${element.id}-${column_index}-${component_index}`}
                      {...component.textarea.style}
                    >
                      <Textarea
                        element={component.textarea}
                        linkClickData={element.anchor_name || element.id}
                      />
                    </TextBlock>
                  )
                }
              })}
            </Box>
          </Column>
        )
      })}
    </StyledFlex>
  </Box>
)

Row.propTypes = {
  element: PropTypes.object,
  lazyload: PropTypes.bool,
}

export default Row
