import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { connect } from "react-redux"

import { checkAppleGooglePay, initCart } from "areas/cart/store/actions"
import { jewlr_t } from "helpers/application"

export default function fetchCart(WrappedComponent) {
  const InternalComponentName = ({
    checkAppleGooglePay,
    loadCart,
    ...props
  }) => {
    useEffect(() => {
      loadCart()
      if (sl.config.enable_apple_google_pay) {
        checkAppleGooglePay()
      }
    }, [])

    return (
      <>
        <Helmet>
          <title>Shopping {jewlr_t("Cart")}</title>
        </Helmet>
        <WrappedComponent {...props} />
      </>
    )
  }

  const mapStateToProps = (state, ownProps) => {
    return {
      cartLoaded: state.cart.cartLoaded,
      serverLoaded: ownProps.cartData && ownProps.history.action === "POP",
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
      dispatch,
    }
  }

  const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps
    return {
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      checkAppleGooglePay: () => {
        dispatch(checkAppleGooglePay())
      },
      loadCart: () => {
        dispatch(initCart())
      },
    }
  }

  InternalComponentName.propTypes = {
    checkAppleGooglePay: PropTypes.func,
    loadCart: PropTypes.func,
  }

  const ComponentName = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(InternalComponentName)

  return ComponentName
}
